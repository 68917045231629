export default function RootSplash() {
  return (
    <div style={{ overflow: "hidden" }}>
      {/* <img
        style={{
          width: "50%",
          maxWidth: "400px",
          marginLeft: "50%",
          transform: "translateX(-50%)",
          marginTop: "200px",
        }}
        src="./splashLogo.png"
        alt="splashLogo"
      /> */}
      <img
        src="white_logo.svg"
        style={{
          height: "80px",
          width: "80px",
          position: "absolute",
          left: "50vw",
          top: "30px",
          transform: "translateX(-50%)",
        }}
      />
      <img
        src="/globe_splash.jpg"
        style={{ width: "100vw", height: "100vh", objectFit: "cover" }}
      />
    </div>
  )
}
