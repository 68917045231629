import { Link } from "react-router-dom";
import gsap from "gsap";
import { useEffect } from "react";

export default function BadPath() {
  useEffect(() => {
    gsap.to("#fourofour", { opacity: 1, duration: 2 }, 1);
  }, []);

  return (
    <div id="fourofour" style={{ opacity: "0", padding: "200px" }}>
      <h1>Sorry this page does not exist.</h1>
      <Link to="/">Go Home</Link>
    </div>
  );
}
